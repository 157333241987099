@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Katibeh:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kadwa:wght@700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-montserrat: Montserrat;
  --font-kadwa: Kadwa;
  --font-katibeh: Katibeh;
  --font-lato: Lato;

  /* font sizes */
  --font-size-xl: 20px;
  --font-size-3xs: 10px;
  --font-size-5xs-9: 7.9px;
  --font-size-24xl-4: 43.4px;
  --font-size-base: 16px;
  --font-size-29xl: 48px;

  /* Colors */
  --color-white: #fff;
  --color-royalblue-100: #004aad;
  --color-royalblue-200: rgba(0, 74, 173, 0.4);
  --color-gray-100: rgba(0, 0, 0, 0.85);
  --color-gray-200: rgba(0, 0, 0, 0.7);
  --color-black: #000;

  /* Gaps */
  --gap-5xl: 24px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;

  /* Border radiuses */
  --br-xl: 20px;
  --br-mini: 15px;
}
