.p-image{
  width: 80%;
  height: 100%;
}

.journeyText{
  font-family: "Lato";
  font-weight: 500;
  font-size: 20px;
  text-align: justify;
}

.k-sub-div{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  
}

.card-text-div{
  font-size:32px;
}

.span-text-div{
   color:#004AAD;
   margin-left:3px;
   font-weight:600;
   font-size:20px
}

.m-c{
  display: none;
}

.w-c{
  display: flex;
  justify-content: center;
}

.img-text{
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  text-align: justify;
}

.card-body{
  margin: 0 0 20px 0;
  padding: 0;

}

.text-bold{
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 36px;
  color: #004AAD;
}

.heading{
  font-weight: bold;
  font-family: "Montserrat";
  font-size: 24px;
}


@media  screen and (max-width:1200px) {
  .journeyimg{
    width: 90%;
    height: 90%;
  }
  .img-text{
  font-weight: 500;
  font-size: 12px;
  }
  .f1{
    display: flex;
    justify-content: start;
  }
  .journeyText{
    font-family: "Lato";
    font-weight: 500;
    font-size: 12px;
  }
  /* you can use these font styles for desired class names replace the text class name to see the effect */
  .lato-regular {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

 
  .m-c{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 18px;
    margin-top:10px
  }
  .w-c{
    display: none;
  }
  .main-cont{
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-around;
    
  }

  .heading{
    font-weight: bold;
    font-family: "Montserrat";
    font-size: 14px;
  }
  .img-cont{
    width: 100%;
  }
  .card-text-div{
    font-size: 14px;
  }

  .img-text{
    font-family: "Lato","san-serif";
    font-weight: 500;
    font-size: 12px;
  }
  
.span-text-div{
  color:#004AAD;
  margin-left:3px;
  font-weight:600;
  font-size:12px
}

.card-sub-text{
  font-family: "Lato";
  font-size: 10px;
  font-weight: 500;
}
}

.f1{
  display: flex;
  justify-content: end;
}

.s-image{
  width: 80%;
  height: 100%;
  margin: 3;
}

.f2-row{
  display: flex;
  flex-direction: row-reverse;
}

.center-div{
  margin-bottom: 15px;
}

.col-div{
  width: 80%;
  height: 100%;
}

.col-div-main{
  margin-top:'2px'
}

