@import url('https://fonts.googleapis.com/css2?family=Kadwa:wght@400;700&family=Katibeh&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
header,
footer {
    height: 20px;
    background-color: #d84d4d;
}

main {
    flex-grow: 1;
    /* Allow the content to take up remaining space */
}

.breakbtnMain{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.breakbtns-3{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.spotlight-header {
    margin-top: -2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spotlight-title {
    color: #000;
    font-family: Montserrat;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 2rem;
}

.spotlight-images {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    gap: 3.5rem;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.spotlight-image-1 {
    fill: #CBE1FF;
    stroke-width: 3px;
    stroke: #004AAD;
    flex-shrink: 0;
}

.product-filter {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 2%;
    margin-left: 4rem;
    margin-right: 4rem;
}

.product-btn-des {
    color: black;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.btnIcon{
    width:20px;
    height:20px;
    margin-right:10px;
}

.product-button-1 {
    display: flex;
    padding: 0.5rem 3rem;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    border: 3px solid #004AAD;
    background: #fff;
    width: 100%;
    margin-top: 10px;
}

.product-button-1:hover {
    background: #004AAD;
    cursor: pointer;
}

.btn-des {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
}

.product-btn-des:hover {
    color: #fff;
}

.product-more-filter {
    display: inline-flex;
    align-items: center;
    margin-top: 3rem;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-bottom: 10%;
    gap: 20vw;
}

.filters-products {
    display: flex;
    align-items: flex-start;
    gap: 1.2rem;
    width: 90%;
}

.filters-btn {
    display: flex;
    padding: 1rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    border: 3px solid #004AAD;
    background: #fff;
    color: rgba(0, 0, 0, 0.60);
    font-family: Montserrat;
    font-size: 0.8rem;
    width: 90%;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    width:20rem;
}

.filters-btn:hover {
    background: #004AAD;
    color: #fff;
}

.products-sort {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.total-products {
    color: rgba(0, 0, 0, 0.80);
    font-family: Montserrat;
    font-size: 0.95rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.products-dropdown {
    width: 9.3rem;
    padding: 5px 3.7rem;
    gap: 0.6rem;
    border-radius: 1rem;
    border: 2px solid #004AAD;
}

.drop-btn {
    color: rgba(0, 0, 0, 0.80);
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: none;
    border: none;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
    color: #000;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.prod-display {
    margin-top: 3rem;
    margin-left: 4rem;
    margin-right: 4rem;
}

.prod-display-1 {
    display: inline-flex;
    align-items: flex-start;
    gap: 1.3rem;
    margin-bottom: 3rem;
}


.discount-tag {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    padding: 5px;
    border-top-right-radius: 5px;
}
.prod-card-image {
    display: inline-flex;
    padding: 28px 39px 10px 60.5px;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    border: 2px solid rgba(0, 74, 173, 0.09);
}

.prod-card-title {
    color: rgba(0, 0, 0, 0.85);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.btn-prod-card {
    display: flex;
    margin-top: 2rem;
    width: 100%;
    padding: 10px 46px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #004AAD;
}

.buynow-btn {
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.product-display{
    display:flex;
    gap:1rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom:5rem;
}
.product-display .card{
    width:15rem;
    display:flex;
    flex-direction: column;
    gap:0.5rem;
    border:2px solid #98bdf0;
    border-radius:1rem;
    padding:1rem 2rem;
    justify-content: center;
    align-items: center;
    height:20rem;
}
.product-display img{
 width:12rem;
 height:15rem;
}
.product-display button{
    background:#4487e5;
    border:none;
    padding:1rem 1.5rem;
    color:white;
    font-weight: bold;
    font-size: large;
    border-radius:0.5rem;
}
.prod-display{
    display: flex;
    flex-direction: row;
}

.prod-display-1{
   
margin-bottom: 3rem; 

}



.prod-card{
    border-radius: 0.75rem;
border: 1px solid rgba(0, 74, 173, 0.40);
background: #FFF;
padding:1rem 1.5rem;
/* width:280px; */
/* position: relative;
overflow-y: hidden; */
}
.prod-card-image{
    /* display: inline-flex; */
    display:flex;
/* padding: 28px 39px 10px 60.5px; */
/* justify-content: flex-end; */
justify-content:center;
align-items: center;
border-radius: 0.5rem;
border: 2px solid rgba(0, 74, 173, 0.09);
background: #CBE1FF;
margin-bottom:10px;
/* width:300px !important; */
/* height:300px  */
}
.prod-card-title{
    color: rgba(0, 0, 0, 0.85);
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.btn-prod-card{
    display: flex;
    margin-top: 2rem;
    width: 100%;
padding: 10px 46px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
background: #004AAD;
outline:none;
border:none;
}
.buynow-btn{
    color: #FFF;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.p-img{
    width: 100%;
}
.product-filter {
    display: flex;
    justify-content: space-around;
}

.product-button-1.active {
    /* Your active button styles */
    background-color: #004AAD;
    color:white
}

.birbtn{
    display: flex;
    flex-direction: row;
    gap:10px;
    width: 100%;
}

.teabtn{
    display: flex;
    flex-direction: row;
    gap:10px
}
.product-btn-des-w{
    display: block;
}


.product-btn-des-m{
    display: none;
}


@media screen and (max-width:1200px) {
    .product-box{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    }
.p-text{
    margin-left: 5px;
    font-size: 10px;
    font-weight: 600;
}

.prod-d{
    display: flex;
    flex-direction: column;
    
}

.breakbtnMain{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.breakbtns-3{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.buynow-btn{
    color: #FFF;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top:10px;
    padding: 10px;
}

.filters-btn {
    display: flex;
    padding-top:10px ;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    border: 3px solid #004AAD;
    background: #fff;
    color: rgba(0, 0, 0, 0.60);
    font-family: Montserrat;
    font-size: 0.8rem;
    width: 90%;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width:100px;
}

.birbtn{
    display: flex;
    flex-direction: row;
    gap:10px;
    width: 100%;
}

.teabtn{
    display: flex;
    flex-direction: row;
    gap:10px
}
.prod-card-title{
    color: rgba(0, 0, 0, 0.85);
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.prod-card{
    padding: 5px;
}
.prod-card-main{
    padding: 5px;
}
.p-cont{
    width: 100%;
}


.p-img{
    width: 100%;
}
.product-filter {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        
        /* flex-wrap:wrap; */
    }
    .product-button-1 {
        
        width: 10%;
    }
    .btn-des {
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        
    }
    .product-btn-icon{
        width: 10px;
    }
   
    .product-more-filter{
        display: flex;
    }
    .filters-products {
       
        /* flex-wrap: wrap; */
        flex-direction: column;
        width:100%;
        justify-content: center;
        align-items: center;
        /* margin-left: -1.4rem; */
    }
    .p-cont{
        display: flex;
        flex-direction: column;
    }
    .product-btn-des{
        font-size:10px
    }
    .product-btn-des-w{
        display: none;
    }
    .prod-btn-des-m{
        display: block;
    }
    
    .prod-display{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
} 

    
@media screen and (min-width:771px) and (max-width:1120px) {
    .product-box{
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items:center;
    }
    .product-filter {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* flex-wrap:wrap; */
    }
    .product-button-1 {
      
        width: 91%;
    }
   
    .btn-des {
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        
    }
    
    .filters-products {
       
        /* flex-wrap: wrap; */
        flex-direction: column;
        width:100%;
        justify-content: center;
        align-items: center;
        /* margin-left: 1.4rem; */
    }
    .prod-display{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
} 
    

