.w-image{
    position: absolute;
    width: 90px;
    height: 90px;
    top: 85%;
    left: 90%;
    z-index: 999;
    position: fixed;
}

.main{
    position: relative;
}

@media screen  and (max-width:600px) {
    .w-image{
        position: absolute;
        width: 70px;
        height: 70px;
        top: 90%;
        left: 80%;
        z-index: 999;
        position: fixed;
    }
    
.main{
    position: relative;
}
}