.col-6-1images{
    width: 80%;
    height: 100px;
    
}


.col-6-2images{
    width: 80%;
    height: 500px;
}

.matter-cont{
    width: 100%;
    justify-content: center;
}


.best-seller-image{
    width: 70%;
    height: 70%;
}

.nvimage{
    display: none;
}

.home-text{
    color:#004AAD;
    font-weight: 600;
    font-size: 32px;   
}
.who-span{
    margin-bottom: 20px;
}


.home-text {
    /* Optional: Additional styles for home text */
    font-weight: bold;
    font-size: 36px;
}

.k-sub-div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    
}

.headings{
  font-weight:600;
  font-size:26px;
  color:#000000;
  text-decoration:underline;
}

.p-m{
  border: 1px solid #004AAD66;
  background-color:#83b1ee66;
  ;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.who-img{
  width: 80%;
  height: 80%;
}
.p-t{
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 20px;
  font-style: normal;
}

.img{
  width: 50%;
  height: 70%;
}
.who-span-text{
    color:#000000B2;
    font-family: "Lato";
    font-weight: 500;
    font-size: 20px;
    font-style: normal;
    text-align: justify;
}

.k-image{
    width: 100px;
}
.home-text-black{
    color: #000000;
    font-weight: 600;
    font-size: 20px;
}

.text-k{
  font-family: "Montserrat";
    font-weight: 600;
    font-size: 20px;
    text-align: center;
}

.text-k-div{
    display:flex;
    justify-content:'center';
    align-items: center;
}




.home-i-text{
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 20px;
  font-style: normal;
  text-align: justify;
}


.outer{
  display: none;

}

.f-ic{
  margin: 0;
}

.links{
  list-style: none;
  justify-content: flex-start;
  margin-left:-10px;
  padding: 0;
}

.footer-icon{
  width:30px;
  height:30px;
  margin-right:10px;
}

.footerText{
  margin:0;
  padding: 0;
}
.footer-nutri{
  width: 161px;
  height: 38px;
  margin-left: 20px;
}


/* Add additional styles for indicators, controls, etc. as needed */


.footer-main{
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.footer-address{
   align-items:flex-end;
}

.footer-text-ad{
  margin: 0;
  padding: 0;
}

.webFooter{
  display: block;
  height: 350px;
  background-color: #004AAD;
}

.mobileFooter{
  display: none;
}

@media screen and (max-width:1200px){
  .row-r3{
    display: flex;
    flex-direction: row;
  }
  
.nav-link{
  padding-right: 10px;
  
}

  .webFooter{
    display: none;
  }
  
.who-img{
  width: 100%;
  height: 90%;
}

  .mobileFooter{
    display: block;
  }


  .navbar{
    flex-direction: row;
     gap: 10;
     justify-content: space-around;
     width: 100%;
     padding-bottom: 0;
     padding-left: 20px;
 }
 .navigationbtns{
  width: 100%;
 }
  .footer-address{
    
    align-items:flex-start;
 }

  .footer-main{
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  .f-ic{
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .mobile{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .footer-nutri{
      width: 190px;
      height: 50px;
      margin-left: 13px;
    }

    .home-text-black{
        color: #000000;
        font-weight: 600;
        font-size: 10px;
        text-align: center;
    }

    .k-image{
      width: 40px;
      height: 40px;
    }

    .outer{
      display: flex;
      width: 300px;  
    }

    .cont{
      width: 100%;
      margin-bottom: 10px;
    }

    .img{
      width: 100px;
      height: 100%;
    }
    .p-m{
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .sub-cont{
      display: flex;
      width: 100%;
      margin-bottom: 10px;
    }
    .sub-cont div{
      width: 150px;
      margin-right: 10px;
    }
    .dummy{
      display: none;
    }
    .text-div{
      margin-left: 68px;
    }
    .links{
      list-style: none;
      justify-content: center;
    }

    
    
.who-span-text{
    color:#000000B2;
    font-weight:500;
    font-size:12px;
    text-align: justify;
}
    
.who-text-div{
 margin-left: 20px;   
}
    
.text-div-2{
 margin-left: 80px;   
}

    .text-sub-1
    .text-sub-2{
        display: flex;
        justify-content: center;
        align-items: center;
        
    }

    .footerText{
      margin:0;
      padding: 0;
    }

    .home-text{
       font-size: 16px;
    }
    .component-38-icon{
        width: 32px;
        height: 32px;
    }
    .external{
        font-size: 10px;
    }
   /* CSS */
.nvimage {
    display: block;
    position: relative; /* Ensure position is relative to contain absolute positioned child */
    width: 100%; /* Ensure div fills its parent width */
    height: 30%; /* Ensure div fills its parent height */
    overflow: hidden; /* Hide any overflow content */
    background-color: #004AAD; /* Optional: Set background color */
    
  }
.image-bs{
    margin-top: 20px;
    margin-bottom: 10px;
}

.bs-i{
    height: 80%;
    width: 80%;
}

.text-k{
  font-size: 7px;
}


.text-div{
   align-items: flex-end;
}
.navp {
    display: block; /* Ensure image is displayed as a block element */
    width: auto; /* Allow the image to adjust its width */
    height: auto; /* Allow the image to adjust its height */
    max-width: 34px; /* Limit image width to fit within parent */
    max-height: 34px; /* Limit image height to fit within parent */
}
.text-sub-2{
 margin-bottom: 20px;
 justify-content: flex-start;
 margin-left: 0px;   
}

.text-white{
  font-size: 12px;
}

.home-i-text{
  font-size: 12px;
}

.whoimg{
  margin-left: 15px;
}


}

.image-container {
    width: 100%;
    
    overflow: hidden; /* Hide overflow to prevent scrollbars */
    animation: scroll-images 20s linear infinite; /* Animation for scrolling */
  }
  .text-div {
    display: flex;
   
 /* Align children to the right */
    align-items: center; /* Align children to start from the top */
    flex-direction: column;
    margin-bottom: 20px;
    
}

.text-sub-1
{
    display:flex;
    flex-direction: column;
}

  
  .image-container img {
    display: inline-block;
    width: 50px; /* Assuming all images have the same width */
    animation: scroll-images 20s linear infinite; /* Apply animation to images */
    margin:10px
  }
  
  @keyframes scroll-images {
    from { transform: translateX(0); }
    to { transform: translateX(-100%); } /* Move images 100% to the left */
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    background: #f2f2f2;
  }
  
  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 6px 0;
    background: white;
    white-space: nowrap;
    position: relative;
  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  /* .logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  }
  
  .logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  } */
  
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  
  .logos-slide {
    display: inline-block;
    animation: 20s slide infinite linear;
  }
  
  .logos-slide img {
    height: 50px;
    margin: 0 40px;
  }



  
  
