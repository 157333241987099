.logoimages{
    width: 100%;
    height: 100%;
}

.logo-div{
    width: 30%;
    height: 100%;
}

.right-image{
    display: block;
    width: 58px;
    height: 58px;
}
.navbar-toggler .icon-menu{
    display: none;
  }

  .searchdiv {
    position: relative;
}

.prepend-icon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 10px; /* Adjust padding as needed */
    background-color: #e9ecef; /* Background color of the icon container */
    border: 1px solid #ced4da; /* Border color */
    border-radius: 4px 0 0 4px; /* Rounded corners */
}

.searchbar {
    /* Ensure the input fills the remaining width */
    flex: 1;
    border-radius: 0 4px 4px 0; /* Match the border radius of the input */
}
.searchImg{
  width: 20px;
}

.topNav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.searchbar{
  width: 80%;
  height: 35px;
  border-radius:20px;
  padding-left: 20px;
}

.mobilehide{
  width: 30%;
}
@media screen and (max-width:1200px) {
    .right-image{
        display: none;
    }
    .topNav{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 10px;
    }
    .searchbar{
        width: 100%;
    }

    .searchdiv {
      position: relative;
      
  }
    .logo-div{
      width: 60%;
      height: 100%;
      margin-right: 10px;
  }
  .navbar-toggler .icon-menu{
    display: block;
  }
  .mobilehide{
    width: 0px;
  }

  .searchbar{
    width: 100%;
    height: 35px;
    border-radius:20px;
    padding-left: 20px;
}

  .icon-menu{
    width: 30px;
    height: 30px;
  }

  .w-text{
    display: none;
  }
  .logoimages{
    width: 100%;
    height: 100%;
  }

  .end-div{
    width: 20%;
  }

}

.bar{
    display: none;
}

.navbar{
    width: 100%;   
}






.nav-row-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navbar-main-row{
    width: 100%;
    
}

.navbar-col-1{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}